// @flow
import React from 'react'
import DetailPanel from '../../../../../../components/layout/DetailPanel'
import type { LabOrderResult } from '../../../../../../types'
import styles from './OrderHistory.module.css'
import OrderItemHistory from './OrderItemHistory'

type Props = {|
  order: LabOrderResult
|}

export default function OrderHistory ({ order }: Props) {
  const numberOfItems = order.items.length

  return (
    <div className={styles.panel}>
      <DetailPanel title={'History'}>
        <span className={styles.content}>
          <b className={styles.heading}>Prodigi Lab Order Id: </b>
          {order.prodigiLabOrderId}
        </span>
        {order.merchantOrderReference && order.merchantOrderReference !== '' && <span className={styles.content}>
          <b className={styles.heading}>Merchant Order Reference: </b>
          {order.merchantOrderReference}
        </span>}
        <span className={styles.content}>
          {order.isCancelled && <span className={styles.alert}>CANCELLED</span>}
        </span>

        {order.items.map((item, index) => <OrderItemHistory
          key={item.id + '-' + index}
          index={index}
          isLastItem={numberOfItems === index + 1}
          item={item} />)}
      </DetailPanel>
    </div>
  )
}
